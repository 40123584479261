// Porque levar nossos produtos à sua mesa?

import "./section1.css"
import img1 from "./qualidade01.jpg"
import img2 from "./qualidade02.jpg"
import img3 from "./qualidade03.jpg"

const section1 = () => {
    return(
        <>
            <section className="section1">
                <div className="bg-section1">
                    <div className="section1-titulo">
                        <h1>Porque levar nossos produtos à sua mesa?</h1>
                    </div>
                    <div className="section1-conteudo">
                        <div className="section1-item">
                            <img src={img1} />
                            <p>Excelência na fabricação com matéria-prima de qualidade.</p>
                        </div>

                        <div className="section1-item">
                            <img src={img2} />
                            <p>Produtos fresquinhos e prontos para consumo.</p>
                        </div>

                        <div className="section1-item">
                            <img src={img3} />
                            <p>Atuando a mais de 33 anos no mercado de laticínios.</p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default section1