import React from 'react';
import Familia from './Familia.webp'
import LeiteA2 from './LeiteA2.webp'
import Queijos from './Queijos.webp'
import Revendedor from './Revendedor.webp'
import Vitamina from './Vitamina.webp'
import Mae from './banner-mae.png'

import './Slide.css'


function Slides() {
  return (
    <div id="carouselCrossfade" class="carousel slide carousel-fade relative" data-bs-ride="carousel">
      <div class="carousel-indicators absolute right-0 bottom-0 left-0 flex justify-center p-0 mb-4">
        <button
          type="button"
          data-bs-target="#carouselCrossfade"
          data-bs-slide-to="0"
          class="active"
          aria-current="true"
          aria-label="Slide 1"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselCrossfade"
          data-bs-slide-to="1"
          aria-label="Slide 2"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselCrossfade"
          data-bs-slide-to="2"
          aria-label="Slide 3"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselCrossfade"
          data-bs-slide-to="3"
          aria-label="Slide 4"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselCrossfade"
          data-bs-slide-to="4"
          aria-label="Slide 5"
        ></button>
        {/* <button
          type="button"
          data-bs-target="#carouselCrossfade"
          data-bs-slide-to="5"
          aria-label="Slide 6"
        ></button> */}
      </div>
    <div class="carousel-inner relative w-full overflow-hidden">
    <div class="carousel-item active float-left w-full">
      <img
        src={Familia}
        class="block w-full"
        alt="Banner Família"
      />
    </div>
    {/* <div class="carousel-item float-left w-full">
      <img
        src={Familia}
        class="block w-full"
        alt="Banner Família"
      />
    </div> */}
    <div class="carousel-item float-left w-full">
      <img
        src={LeiteA2}
        class="block w-full"
        alt="Banner Leite A2"
      />
    </div>
    <div class="carousel-item float-left w-full">
      <img
        src={Queijos}
        class="block w-full"
        alt="Banner Queijos"
      />
    </div>
    <div class="carousel-item float-left w-full">
      <img
        src={Revendedor}
        class="block w-full"
        alt="Banner Revendedor"
      />
    </div>
    <div class="carousel-item float-left w-full">
      <img
        src={Vitamina}
        class="block w-full"
        alt="Banner Vitamina"
      />
    </div>
  </div>
  <button
    class="carousel-control-prev absolute top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline left-0"
    type="button"
    data-bs-target="#carouselCrossfade"
    data-bs-slide="prev"
  >
    <span class="carousel-control-prev-icon inline-block bg-no-repeat" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button
    class="carousel-control-next absolute top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline right-0"
    type="button"
    data-bs-target="#carouselCrossfade"
    data-bs-slide="next"
  >
    <span class="carousel-control-next-icon inline-block bg-no-repeat" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div>
  );
}

export default Slides;