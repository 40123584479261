// Horário de Funcionamento

import "./section2.css"


const section2 = () => {
    return (
        <>
            <section className="section2">
                <div className="section2-conteudo">
                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0,0,256,256">
                        <g fill="#ffffff" fillRule="nonzero" stroke="none" strokeWidth="1" strokeLinecap="butt" strokeLinejoin="miter" strokeMiterlimit="10" strokeDasharray="" strokeDashoffset="0" fontFamily="none" fontWeight="none" fontSize="none" textAnchor="none" style={{ mixBlendMode: 'normal' }}>
                            <g transform="scale(10.66667,10.66667)">
                                <path d="M12,0c-6.62891,0 -12,5.37109 -12,12c0,6.62891 5.37109,12 12,12c6.62891,0 12,-5.37109 12,-12c0,-6.62891 -5.37109,-12 -12,-12zM12,2c5.52344,0 10,4.47656 10,10c0,5.52344 -4.47656,10 -10,10c-5.52344,0 -10,-4.47656 -10,-10c0,-5.52344 4.47656,-10 10,-10zM10.9375,3.875l-0.4375,8.1875l0.09375,0.875l6.15625,5.4375l0.96875,-1l-5.09375,-5.40625l-0.4375,-8.09375z"></path>
                            </g>
                        </g>
                    </svg>
                    <p>Funcionamento de Segunda à sexta das 8h às 17h</p>
                </div>
            </section>
        </>
    )
}

export default section2