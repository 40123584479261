// Vídeo da Fazenda

import "./section3.css"
import fazenda from "./milkmenk-institucional.mp4"

const section3 = () => {
    return (
        <>
            <section className="section3">
                <div className="bg-section3">
                    <div className="section3-titulo">
                        <h1>Conheça nossa Fábrica</h1>
                    </div>
                    <div className="section3-video">
                        <video controls>
                            <source src={fazenda} type="video/mp4" />
                        </video>
                    </div>
                    {/* <div className="section3-botao">
                        <a href="#">Assista outros vídeos</a>
                        <a href="#">Acesse nossa galeria de fotos</a>
                    </div> */}
                </div>
            </section>
        </>
    )
}

export default section3