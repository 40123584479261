// Vídeo da Fazenda

import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import "./section4.css"
import coxinha from "./coxinha.png"
import torta from "./Torta-cremosa-de-pacoca-768x536.png"
import macarrao from "./Macarrao-ao-queijo-e-tomates-assados-768x536.png"
import fondue from "./Fondue-de-Queijo.png"
import bolo from "./bolo-milkmenk.webp"
import pao from "./pao-de-queijo-com-creme-de-leite.webp"
import batata from "./batata-gratinada-na-manteiga.webp"

const section4 = () => {
    return (
        <>
            <section className="section4">
                <h1>Receitas</h1>
                <div className="section4-container">

                    <div className="section4-item">
                    <Link to="/Receitas"><a></a></Link>
                        <div className="section4-img">
                            <img src={torta} alt="Torta Cremosa de Paçoca" />
                        </div>
                        <div className="section4-descricao">
                            <span>Receitas Laticínios Milk Menk</span>
                            <span>Torta cremosa de paçoca</span>
                        </div>
                    </div>

                    <div className="section4-item">
                    <Link to="/Receitas"><a></a></Link>
                        <div className="section4-img">
                            <img src={macarrao} alt="Macarrão ao Queijo e Tomates Assados" />
                        </div>
                        <div className="section4-descricao">
                            <span>Receitas Laticínios Milk Menk</span>
                            <span>Macarrão ao Queijo e Tomates Assados</span>
                        </div>
                    </div>

                    <div className="section4-item">
                    <Link to="/Receitas"><a></a></Link>
                        <div className="section4-img">
                            <img src={bolo} alt="Fondue de Queijos" />
                        </div>
                        <div className="section4-descricao">
                            <span>Receitas Laticínios Milk Menk</span>
                            <span>Bolo Milk Menk</span>
                        </div>
                    </div>

                    <div className="section4-item">
                    <Link to="/Receitas"><a></a></Link>
                        <div className="section4-img">
                            <img src={batata} alt="Coxinha com Frango e Requeijão" />
                        </div>
                        <div className="section4-descricao">
                            <span>Receitas Laticínios Milk Menk</span>
                            <span>Batata Gratinada na Manteiga</span>
                        </div>
                    </div>

                    <div className="section4-item">
                    <Link to="/Receitas"><a></a></Link>
                        <div className="section4-img">
                            <img src={pao} alt="Coxinha com Frango e Requeijão" />
                        </div>
                        <div className="section4-descricao">
                            <span>Receitas Laticínios Milk Menk</span>
                            <span>Pão de Queijo com Creme de Leite</span>
                        </div>
                    </div>

                </div>
            </section>
        </>
    )
}

export default section4