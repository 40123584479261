import './Footer.css'
import { FaLinkedinIn, FaFacebookF, FaInstagram, FaWhatsapp, FaCopyright, FaCopy } from 'react-icons/fa';
import { FooterCopyright } from 'flowbite-react/lib/esm/components/Footer/FooterCopyright';
import { Link } from 'react-router-dom'


const Footer = () => {
    return (
        <footer>
            <div class="container-footer">
                <div class="row-footer">

                    <div class="footer-col">
                        <h4>Empresa</h4>
                        <ul>
                            <li><a href="/Historia"> Quem somos </a></li>
                            <li><a href=""> nossos serviços </a></li>
                            <li><a href=""> política de privacidade </a></li>
                            {/* <li><a href=""> programa de afiliados</a></li> */}
                        </ul>
                    </div>


                    <div class="footer-col">
                        <h4>Parceiros</h4>
                        <ul>
                            <Link to="/Boituva"><li><a>Boituva</a></li></Link>
                            {/* <Link to="/Campinas"><li><a>Campinas</a></li></Link> */}
                            <Link to="/Capivari"><li><a>Capivari</a></li></Link>
                            <Link to="/CesarioLange"><li><a>Cesario Lange</a></li></Link>
                            <Link to="/Indaiatuba"><li><a>Indaiatuba</a></li></Link>
                            <Link to="/Itu"><li><a>Itu</a></li></Link>
                            <Link to="/PortoFeliz"><li><a>Porto Feliz</a></li></Link>
                            <Link to="/Sorocaba"><li><a>Sorocaba</a></li></Link>
                            <Link to="/Tiete"><li><a>Tiete</a></li></Link>
                        </ul>
                    </div>


                    <div class="footer-col">
                        <h4>Sobre Nós</h4>
                        <ul>
                            <li><a href="/Historia">A Fazenda</a></li>
                            <li><a href="#">Consiência Sustentável</a></li>
                            <Link to="/Contato"><li><a href="#">Contato</a></li></Link>
                            <Link to="/FAQ"><li><a href="#">Dúvidas</a></li></Link>
                            <Link to="/Historia"><li><a href="#">História</a></li></Link>
                            <li><a href="/Historia">Valores</a></li>
                        </ul>
                    </div>


                    <div class="footer-col">
                        <h4>Receba Nossas Novidades!</h4>
                        <div class="form-sub">
                            <form>
                                <input type="email" placeholder="Digite o seu e-mail" required />
                                <button>Increver-se</button>
                            </form>
                        </div>

                        <div class="medias-socias">
                            <a href="https://www.facebook.com/MilkMenkPortoFeliz/"> <FaFacebookF /> </a>
                            <a href="https://www.instagram.com/milkmenkportofeliz/"> <FaInstagram /> </a>
                            <a href="https://api.whatsapp.com/send/?phone=5515998194162"> <FaWhatsapp /> </a>
                            {/*<a href="#"> <FaLinkedinIn /> </a>*/}
                        </div>

                    </div>
                </div> 
            </div>
            
            {/* <div className='copyright'>
                <p>Copyright &copy;2023 Milk Menk. Designer by <span><a href='https:www.worldindesigner.com.br' target={'_blank'}>World In Designer</a></span></p>
            </div> */}

            <div className='copyright'>
                <p>Copyright &copy;2024 Milk Menk. Todos os direitos reservados.</p>
            </div>

            <div class="desenvolvimento">
                <div class="world-in-designer">
                    <img src="https://cdn.worldindesigner.com.br/imagens/desenvolvido-por-wid.png" alt="Desenvolvido por World In Designer"/>
                </div>
            </div>

        </footer>
    )
}

export default Footer