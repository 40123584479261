import { Link } from 'react-router-dom'
import './Box.css'

import Coalhada from "./Coalhada.png"
import Iogurte from "./Iogurte.png"
import DoceDeLeite from "./DoceDeLeite.png"
import Leite from "./Leite.png"
import Queijo from "./Queijo.png"
import Requeijao from "./Requeijao.png"

const Box = () => {
    return(
    <section className='bg-box'>
        <div class="main">
            <div className='box-grid'>
                <div class="box-produto">
                    <div class="box-img">
                        <img src={Iogurte} alt="Foto Perfil" />
                        <div className='titulo-produto'><h1>Iogurte</h1></div>
                    </div>
                    <div class="content">
                        <h3>Iogurte</h3>
                        <p>Conheça toda linha de Iogurtes Milk Menk</p>
                        <Link to="/Iogurte"><a className='btn btn-primary'>Confira agora!</a></Link>
                    </div>
                </div>
                
                <div class="box-produto">
                    <div class="box-img">
                        <img src={Leite} alt="Foto Perfil" />
                        <div className='titulo-produto'><h1>Leite</h1></div>
                    </div>
                    <div class="content">
                        <h3>Leite</h3>
                        <p>Conheça nosso Leite Milk Menk</p>
                        <Link to="/Leite"><a className='btn btn-primary'>Confira agora!</a></Link>
                    </div>
                </div>

                <div class="box-produto">
                    <div class="box-img">
                        <img src={Coalhada} alt="Foto Perfil" />
                        <div className='titulo-produto'><h1>Coalhada</h1></div>
                    </div>
                    <div class="content">
                        <h3>Coalhada</h3>
                        <p>Conheça toda a linha de Coalhadas Milk Menk</p>
                        <Link to="/Coalhada"><a className='btn btn-primary'>Confira agora!</a></Link>
                    </div>
                </div>

                <div class="box-produto">
                    <div class="box-img">
                        <img src={Queijo} alt="Foto Perfil" />
                        <div className='titulo-produto'><h1>Queijo</h1></div>
                    </div>
                    <div class="content">
                        <h3>Queijo</h3>
                        <p>Conheça toda linha de Queijos Milk Menk</p>
                        <Link to="/Queijos"><a className='btn btn-primary'>Confira agora!</a></Link>
                    </div>
                </div>
                
                <div class="box-produto">
                    <div class="box-img">
                        <img src={DoceDeLeite} alt="Foto Perfil" />
                        <div className='titulo-produto'><h1>Doce de Leite</h1></div>
                    </div>
                    <div class="content">
                        <h3>Doce de Leite</h3>
                        <p>Conheça nosso Doce de Leite Milk Menk</p>
                        <Link to="/DoceDeLeite"><a className='btn btn-primary'>Confira agora!</a></Link>
                    </div>
                </div>

                <div class="box-produto">
                    <div class="box-img">
                        <img src={Requeijao} alt="Foto Perfil" />
                        <div className='titulo-produto'><h1>Requeijão</h1></div>
                    </div>
                    <div class="content">
                        <h3>Requeijão</h3>
                        <p>Conheça nossa Requeijão Cremoso Milk Menk</p>
                        <Link to="/Requeijao"><a className='btn btn-primary'>Confira agora!</a></Link>
                    </div>
                </div>
            </div>
        </div>
    </section>
    );
}

export default Box