import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';
import logo from './logo.png'

const Navbar = () => {
  const [isActive, setIsActive] = useState(false);
  const [isPartnerDropdownOpen, setIsPartnerDropdownOpen] = useState(false); // Novo estado para controlar o dropdown de Parceiros

  const handleMenuToggle = () => {
    setIsActive(!isActive);
  };

  const handlePartnerDropdownToggle = () => {
    setIsPartnerDropdownOpen(!isPartnerDropdownOpen); // Alternar o estado do dropdown de Parceiros
  };

  const closeMenu = () => {
    setIsActive(false);
    setIsPartnerDropdownOpen(false); // Fechar o dropdown de Parceiros ao clicar em um link
  };

  return (
    <header className={isActive ? 'active' : ''}>
      <a href='/' className='logo'><img src={logo} alt="Logo" /></a>
      <div className={`menuToggle ${isActive ? 'active' : ''}`} onClick={handleMenuToggle}></div>
      <nav>
        <ul>
          <li>
            <a href='#' onFocus={handlePartnerDropdownToggle} onBlur={handlePartnerDropdownToggle}>
              Produtos
              <svg
                stroke="currentColor"
                fill="currentColor"
                strokeWidth="0"
                viewBox="0 0 320 512"
                className="FaAngleDown"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z"></path>
              </svg>
            </a>
            <ul  className={isPartnerDropdownOpen ? 'active' : ''}>
              <li><Link onClick={closeMenu} to="/Coalhada">Coalhada</Link></li>
              <li><Link onClick={closeMenu} to="/CremeDeLeite">Creme de Leite</Link></li>
              <li><Link onClick={closeMenu} to="/DoceDeLeite">Doce de Leite</Link></li>
              <li><Link onClick={closeMenu} to="/Iogurte">Iogurte</Link></li>
              <li><Link onClick={closeMenu} to="/Leite">Leite</Link></li>
              <li><Link onClick={closeMenu} to="/Manteiga">Manteiga</Link></li>
              <li><Link onClick={closeMenu} to="/ManteigaGhee">Manteiga Ghee</Link></li>
              <li><Link onClick={closeMenu} to="/Queijos">Queijos</Link></li>
              <li><Link onClick={closeMenu} to="/Requeijao">Requeijão</Link></li>
            </ul>
          </li>
          <li>
            <a href='#' onFocus={handlePartnerDropdownToggle} onBlur={handlePartnerDropdownToggle}>
              Parceiros
              <svg
                stroke="currentColor"
                fill="currentColor"
                strokeWidth="0"
                viewBox="0 0 320 512"
                className="FaAngleDown"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z"></path>
              </svg>
            </a>
            <ul  className={isPartnerDropdownOpen ? 'active' : ''}>
              <li><Link onClick={closeMenu} to="/Boituva">Boituva</Link></li>
              {/* <li><Link onClick={closeMenu} to="/Campinas">Campinas</Link></li> */}
              <li><Link onClick={closeMenu} to="/Capivari">Capivari</Link></li>
              <li><Link onClick={closeMenu} to="/CesarioLange">Cesário Lange</Link></li>
              <li><Link onClick={closeMenu} to="/Indaiatuba">Indaiatuba</Link></li>
              <li><Link onClick={closeMenu} to="/Itu">Itu</Link></li>
              <li><Link onClick={closeMenu} to="/PortoFeliz">Porto Feliz</Link></li>
              <li><Link onClick={closeMenu} to="/Sorocaba">Sorocaba</Link></li>
              <li><Link onClick={closeMenu} to="/Tiete">Tietê</Link></li>
            </ul>
          </li>
          <li>
            <a href='#' onFocus={handlePartnerDropdownToggle} onBlur={handlePartnerDropdownToggle}>
              Sobre a fazenda
              <svg
                stroke="currentColor"
                fill="currentColor"
                strokeWidth="0"
                viewBox="0 0 320 512"
                className="FaAngleDown"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z"></path>
              </svg>
            </a>
            <ul  className={isPartnerDropdownOpen ? 'active' : ''}>
              <li><Link onClick={closeMenu} to="/Historia">História</Link></li>
              <li><Link onClick={closeMenu} to="/Valores">Valores</Link></li>
            </ul>
          </li>
          <li><a href='/Receitas'>Receitas</a></li>
          <li><a href='/Contato'>Contato</a></li>
          <li><a href='/FAQ'>FAQ</a></li>
        </ul>
      </nav>
    </header>
  );
};

export default Navbar;
